import {assertWithResponse} from '~/utils/assert';
import {supportedChains} from '../supportedChains';

export function requireConfiguredChain(chainId: string) {
  const chain = supportedChains.find(chain => chain.chainId === chainId);
  assertWithResponse(typeof chain !== 'undefined', `Chain '${chainId}' not found`, {status: 404});
  assertWithResponse(chain.rpcEndpoints.length > 0, `Chain '${chainId}' has no RPC endpoints configured`, {
    status: 404,
  });

  return chain;
}
