/* eslint-disable no-template-curly-in-string */
import type {SupportedChain} from './types';

export const supportedChains: SupportedChain[] = [
  {
    chainId: 'cosmoshub-4',
    chainName: 'Cosmos Hub',
    accountAddressPrefix: 'cosmos',
    stakingCoin: {
      decimals: 6,
      denom: 'uatom',
      symbol: 'ATOM',
    },
    coinGeckoId: 'cosmos',
    undelegationPeriod: 21,
    txExplorerLink: 'https://www.mintscan.io/cosmos/txs/${txHash}',
    accountExplorerLink: 'https://www.mintscan.io/cosmos/account/${accountAddress}',
    validatorExplorerLink: 'https://www.mintscan.io/cosmos/validators/${validatorAddress}',
    rpcEndpoints: [
      'https://cosmoshub-rpc.stakely.io',
      'https://rpc-cosmoshub.keplr.app',
      'https://cosmos-mainnet-rpc.allthatnode.com:26657',
      'https://cosmos-rpc.polkachu.com',
      'https://rpc.cosmos.directory/cosmoshub',
      'https://rpc-cosmoshub.blockapsis.com',
      'https://cosmos-rpc.quickapi.com:443',
      'https://rpc-cosmoshub.whispernode.com',
      'https://cosmoshub.validator.network/',
      'https://cosmoshub-rpc.lavenderfive.com:443',
      'https://rpc.cosmoshub.strange.love',
      'https://rpc.cosmos.network:443',
      'https://rpc-cosmoshub.ecostake.com',
      'https://rpc-cosmoshub.pupmos.network',
      'https://cosmos-rpc.polkachu.com',
      'https://cosmoshub.rpc.interchain.ivaldilabs.xyz',
      'https://rpc-cosmoshub.goldenratiostaking.net',
      'https://rpc-cosmoshub-ia.cosmosia.notional.ventures/',
      'https://rpc.cosmos.interbloc.org',
      'https://rpc.cosmos.silknodes.io',
      'https://atom-rpc.kleomedes.network',
      'https://rpc-cosmoshub.architectnodes.com',
      'https://rpc.cosmos.dragonstake.io',
      'https://cosmoshub.rpc.stakin-nodes.com',
      'https://cosmos-rpc.icycro.org',
      'https://rpc.cosmos.bh.rocks',
    ],
  },
  {
    chainId: 'osmosis-1',
    chainName: 'Osmosis',
    accountAddressPrefix: 'osmo',
    stakingCoin: {
      decimals: 6,
      denom: 'uosmo',
      symbol: 'OSMO',
    },
    coinGeckoId: 'osmosis',
    undelegationPeriod: 14,
    txExplorerLink: 'https://www.mintscan.io/osmosis/txs/${txHash}',
    accountExplorerLink: 'https://www.mintscan.io/osmosis/account/${accountAddress}',
    validatorExplorerLink: 'https://www.mintscan.io/osmosis/validators/${validatorAddress}',
    rpcEndpoints: [
      'https://osmosis-rpc.stakely.io',
      'https://rpc-osmosis.keplr.app/',
      'https://rpc.cosmos.directory/osmosis',
      'https://rpc.osmosis.zone/',
      'https://rpc-osmosis.blockapsis.com',
      'https://osmosis-rpc.quickapi.com:443',
      'https://rpc-osmosis.whispernode.com',
      'https://osmosis-rpc.lavenderfive.com:443',
      'https://osmosis.validator.network/',
      'https://rpc-osmosis.ecostake.com',
      'https://osmosis-rpc.polkachu.com',
      'https://rpc-osmosis-ia.cosmosia.notional.ventures/',
      'https://rpc.osmosis.interbloc.org',
      'https://rpc.osmosis.silknodes.io',
      'https://osmosis.nodejumper.io',
      'https://osmosis.rpc.stakin-nodes.com',
      'https://rpc.osmosis.goldenratiostaking.net',
      'https://osmosis-rpc.ibs.team',
      'https://osmosis.rpc.interchain.ivaldilabs.xyz',
      'https://rpc.osl.zone',
    ],
  },
  {
    chainId: 'juno-1',
    chainName: 'Juno',
    accountAddressPrefix: 'juno',
    stakingCoin: {
      decimals: 6,
      denom: 'ujuno',
      symbol: 'JUNO',
    },
    coinGeckoId: 'juno-network',
    undelegationPeriod: 28,
    txExplorerLink: 'https://www.mintscan.io/juno/txs/${txHash}',
    accountExplorerLink: 'https://www.mintscan.io/juno/account/${accountAddress}',
    validatorExplorerLink: 'https://www.mintscan.io/juno/validators/${validatorAddress}',
    rpcEndpoints: [
      'https://juno-rpc.stakely.io',
      'https://rpc-juno.itastakers.com',
      'https://rpc.cosmos.directory/juno',
      'https://rpc-juno.whispernode.com',
      'https://rpc-juno.itastakers.com',
      'https://rpc-juno.ecostake.com',
      'https://juno-rpc.polkachu.com',
      'https://juno.rpc.interchain.ivaldilabs.xyz',
      'https://juno-rpc.lavenderfive.com:443',
      'https://rpc-juno.pupmos.network',
      'https://rpc-juno-ia.cosmosia.notional.ventures/',
      'https://rpc.juno.chaintools.tech/',
      'https://rpc.juno.silknodes.io/',
      'https://juno-rpc.kleomedes.network',
      'https://rpc.juno.interbloc.org',
      'https://juno.nodejumper.io',
      'https://juno.rpc.stakin-nodes.com',
      'https://juno-rpc.icycro.org',
      'https://rpc-juno.goldenratiostaking.net',
      'https://rpc.juno.bh.rocks',
      'https://rpc.juno.kingnodes.com',
      'https://juno-rpc.reece.sh',
      'http://juno.rpc.stakeandrelax.net:12657',
    ],
  },
  {
    chainId: 'stargaze-1',
    chainName: 'Stargaze',
    accountAddressPrefix: 'stars',
    stakingCoin: {
      decimals: 6,
      denom: 'ustars',
      symbol: 'STARS',
    },
    coinGeckoId: 'stargaze',
    undelegationPeriod: 14,
    txExplorerLink: 'https://www.mintscan.io/stargaze/txs/${txHash}',
    accountExplorerLink: 'https://www.mintscan.io/stargaze/account/${accountAddress}',
    validatorExplorerLink: 'https://www.mintscan.io/stargaze/validators/${validatorAddress}',
    rpcEndpoints: [
      'https://rpc.stargaze-apis.com',
      'https://rpc.cosmos.directory/stargaze',
      'https://rpc.stargaze-apis.com/',
      'https://rpc-stargaze.pupmos.network',
      'https://rpc-stargaze.ezstaking.dev',
      'https://rpc.stars.kingnodes.com/',
      'https://stargaze-rpc.polkachu.com',
      'https://rpc-stargaze-ia.cosmosia.notional.ventures/',
      'https://stargaze.c29r3.xyz:443/rpc/',
      'https://rpc.stargaze.nodestake.top',
      'https://stargaze-rpc.ibs.team',
      'https://rpc-stargaze.d-stake.xyz',
      'https://rpc.stargaze.silentvalidator.com/',
    ],
  },
  {
    chainId: 'uni-5',
    chainName: 'Juno Testnet',
    accountAddressPrefix: 'juno',
    stakingCoin: {
      decimals: 6,
      denom: 'ujunox',
      symbol: 'JUNOX',
    },
    coinGeckoId: 'juno-network',
    undelegationPeriod: 28,
    txExplorerLink: 'https://testnet.ping.pub/juno/tx/${txHash}',
    accountExplorerLink: 'https://testnet.ping.pub/juno/account/${accountAddress}',
    validatorExplorerLink: 'https://testnet.ping.pub/juno/account/${validatorAddress}',
    rpcEndpoints: [
      'https://rpc.uni.junonetwork.io',
      'https://juno-testnet-rpc.polkachu.com',
      'https://rpc.cosmos.directory/junotestnet',
      'https://rpc.uni.junonetwork.io',
      'https://juno-testnet-rpc.polkachu.com',
    ],
  },
  {
    chainId: 'perun-1',
    chainName: 'Chain4Energy',
    accountAddressPrefix: 'c4e',
    stakingCoin: {
      decimals: 6,
      denom: 'uc4e',
      symbol: 'C4E',
    },
    coinGeckoId: 'fake_id',
    undelegationPeriod: 21,
    txExplorerLink: 'https://explorer.c4e.io/transactions/${txHash}',
    accountExplorerLink: 'https://explorer.c4e.io/accounts/${accountAddress}',
    validatorExplorerLink: 'https://explorer.c4e.io/validators/${validatorAddress}',
    rpcEndpoints: [
      'https://rpc.c4e.io/',
      'https://rpc.c4e.nodestake.top',
      'http://rpc.c4e.ppnv.space:13657',
      'https://c4e.rpc.bccnodes.com',
      'https://m-c4e.rpc.utsa.tech',
      'https://rpc-c4e.d-stake.xyz',
    ],
  },
  {
    chainId: 'core-1',
    chainName: 'Persistence',
    accountAddressPrefix: 'persistence',
    stakingCoin: {
      decimals: 6,
      denom: 'uxprt',
      symbol: 'XPRT',
    },
    coinGeckoId: 'persistence',
    undelegationPeriod: 21,
    txExplorerLink: 'https://www.mintscan.io/persistence/txs/${txHash}',
    accountExplorerLink: 'https://www.mintscan.io/persistence/account/${accountAddress}',
    validatorExplorerLink: 'https://www.mintscan.io/persistence/validators/${validatorAddress}',
    rpcEndpoints: [
      'https://persistence-rpc.polkachu.com',
      'https://rpc.core.persistence.one',
      'https://rpc-persistent-ia.cosmosia.notional.ventures',
      'https://persistence-rpc.kleomedes.network',
      'https://rpc-persistence.architectnodes.com',
      'https://persistence-mainnet-rpc.cosmonautstakes.com',
    ],
  },
  {
    chainId: 'agoric-3',
    chainName: 'Agoric',
    accountAddressPrefix: 'agoric',
    stakingCoin: {
      decimals: 6,
      denom: 'ubld',
      symbol: 'BLD',
    },
    coinGeckoId: 'agoric',
    undelegationPeriod: 21,
    txExplorerLink: 'https://atomscan.com/agoric/transactions/${txHash}',
    accountExplorerLink: 'https://atomscan.com/agoric/accounts/${accountAddress}',
    validatorExplorerLink: 'https://atomscan.com/agoric/validators/${validatorAddress}',
    rpcEndpoints: [
      'https://agoric-rpc.polkachu.com',
      'https://main.rpc.agoric.net:443',
      'https://rpc.agoric.nodestake.top',
      'https://agoric.rpc.kjnodes.com',
      'https://labs-agoric.rockrpc.net',
      'https://agoric.stakesystems.io:2053',
      'https://agoric-mainnet-rpc.allthatnode.com:26657',
    ],
  },
  {
    chainId: 'migaloo-1',
    chainName: 'Migaloo',
    accountAddressPrefix: 'migaloo',
    stakingCoin: {
      decimals: 6,
      denom: 'uwhale',
      symbol: 'WHALE',
    },
    coinGeckoId: 'white-whale',
    undelegationPeriod: 21,
    txExplorerLink: 'https://ping.pub/migaloo/tx/${txHash}',
    accountExplorerLink: 'https://ping.pub/migaloo/account/${accountAddress}',
    validatorExplorerLink: 'https://ping.pub/migaloo/account/${validatorAddress}',
    rpcEndpoints: [
      'https://rpc-whitewhale.carbonzero.zone',
      'https://migaloo-rpc.kleomedes.network',
      'https://whitewhale-rpc.lavenderfive.com',
      'https://whitewhale-mainnet-rpc.autostake.com',
      'https://rpc.whitewhale.nodestake.top',
      'https://rpc-whitewhale.whispernode.com',
      'https://rpc-whitewhale.goldenratiostaking.net',
    ],
  },
  {
    chainId: 'axelar-dojo-1',
    chainName: 'Axelar',
    accountAddressPrefix: 'axelar',
    stakingCoin: {
      decimals: 6,
      denom: 'uaxl',
      symbol: 'AXL',
    },
    coinGeckoId: 'axelar',
    undelegationPeriod: 7,
    txExplorerLink: 'https://ping.pub/axelar/tx/${txHash}',
    accountExplorerLink: 'https://ping.pub/axelar/account/${accountAddress}',
    validatorExplorerLink: 'https://ping.pub/axelar/account/${validatorAddress}',
    rpcEndpoints: [
      'https://rpc-axelar.imperator.co:443',
      'https://axelar-rpc.quickapi.com:443',
      'https://rpc.axelar.bh.rocks',
    ],
  },
  {
    chainId: 'dydx-testnet-1',
    chainName: 'dYdX Public Testnet',
    accountAddressPrefix: 'dydx',
    stakingCoin: {
      decimals: 6,
      denom: 'dv4tnt',
      symbol: 'DV4TNT',
    },
    coinGeckoId: 'dydx',
    undelegationPeriod: 3,
    txExplorerLink: 'https://testnet.mintscan.io/dydx-testnet/tx/${txHash}',
    accountExplorerLink: 'https://testnet.mintscan.io/dydx-testnet/account/${accountAddress}',
    validatorExplorerLink: 'https://testnet.mintscan.io/dydx-testnet/account/${validatorAddress}',
    rpcEndpoints: [
      'https://rpc-dydx-testnet.mms.team',
      'https://dydx-rpc-testnet.enigma-validator.com/',
      'https://dydx-testnet-archive.allthatnode.com:26657',
    ],
  },
  {
    chainId: 'kyve-1',
    chainName: 'Kyve',
    accountAddressPrefix: 'kyve',
    stakingCoin: {
      decimals: 6,
      denom: 'ukyve',
      symbol: 'KYVE',
    },
    coinGeckoId: 'kyve-network',
    undelegationPeriod: 21,
    txExplorerLink: 'https://www.mintscan.io/kyve/tx/${txHash}',
    accountExplorerLink: 'https://www.mintscan.io/kyve/account/${accountAddress}',
    validatorExplorerLink: 'https://www.mintscan.io/kyve/account/${validatorAddress}',
    rpcEndpoints: [
      'https://kyve.rpc.liveraven.net',
      'https://rpc-kyve.ecostake.com',
      'https://kyve-rpc.lavenderfive.com:443',
    ],
  },
];
