import {supportedChains} from '../supportedChains';

class ExplorerTemplateResolver<T extends string> {
  private template: string;
  private values: Record<T, string>;

  constructor(template: string, values: Record<T, string>) {
    this.template = template;
    this.values = values;
  }

  private getValue(key: string): string | undefined {
    return this.values[key as T];
  }

  public toString() {
    return this.template.replace(/\${(\w+)}/g, match => {
      const key = match.slice(2, -1);
      return this.getValue(key) ?? match;
    });
  }
}

class ExplorerLink {
  protected static getChain(chainId: string) {
    return supportedChains.find(c => c.chainId === chainId);
  }
}

export class TxExplorerLink extends ExplorerLink {
  public static fromTemplate(template: string, txHash: string): string;
  public static fromTemplate(template: string, txHash: string | undefined | null): string | undefined;
  public static fromTemplate(template: string, txHash: string | undefined | null) {
    if (!txHash) {
      return undefined;
    }
    return new ExplorerTemplateResolver(template, {txHash}).toString();
  }

  public static fromChainId(chainId: string, txHash: string | undefined | null) {
    const chain = this.getChain(chainId);
    if (!chain || !txHash) {
      return undefined;
    }
    return new ExplorerTemplateResolver(chain.txExplorerLink, {txHash}).toString();
  }
}

export class AccountExplorerLink extends ExplorerLink {
  public static fromTemplate(template: string, accountAddress: string) {
    return new ExplorerTemplateResolver(template, {accountAddress}).toString();
  }

  public static fromChainId(chainId: string, accountAddress: string) {
    const chain = this.getChain(chainId);
    if (!chain) {
      return undefined;
    }
    return new ExplorerTemplateResolver(chain.accountExplorerLink, {accountAddress}).toString();
  }
}

export class ValidatorExplorerLink extends ExplorerLink {
  public static fromTemplate(template: string, validatorAddress: string) {
    return new ExplorerTemplateResolver(template, {validatorAddress}).toString();
  }

  public static fromChainId(chainId: string, validatorAddress: string) {
    const chain = this.getChain(chainId);
    if (!chain) {
      return undefined;
    }
    return new ExplorerTemplateResolver(chain.validatorExplorerLink, {validatorAddress}).toString();
  }
}
